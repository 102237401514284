<template>
  <div :class="{ bigbox: isfankui } + ' bgc_f5f5f5'">
    <Topbar></Topbar>
    <SuS @sus="sus"></SuS>
    <!-- bg -->
    <div class="bg">
      <img src="../../../assets/images/jifenbg.png" alt="" />
    </div>
    <!-- <TypeList :islist="islist"></TypeList> -->
    <TypeList ></TypeList>
    <!-- 筛选 -->
    <div class="screen">
      <div
        :class="[item.ischeck ? 'screen-left' : 'screen-left2']"
        v-for="(item, index) in screenlist"
        :key="index"
        @click="change(index, screenlist)"
      >
        {{ item.name }}
      </div>
      <div class="screen-right">
        <!-- <div class="price">
          <div class="screen-left mr">积分</div>
          <div class="screen-shaixuan" @mousemove="isjf">
            <div class="money"></div>
            <div class="line"></div>
            <div class="money"></div>
          </div>
          <div class="price2" v-if="isJf" @mouseleave="yichu()">
            <input
              class="money"
              placeholder=""
              v-model="minPoints"
              value="minPoints"
            />
            <div class="line"></div>
            <input
              class="money"
              placeholder=""
              v-model="maxPoints"
              value="maxPoints"
            />
            <div class="queding" @click.stop="shaixianmoney()">确定</div>
          </div>
        </div> -->
        <div class="nums">共{{ total }}件商品</div>
        <div class="jiantou"></div>
      </div>
    </div>
    <div class="center">
      <div
        class="center-item"
        v-for="(item, index) in goodsList"
        :key="index"
        @click="togoodsdetail(item.Id)"
      >
        <img :src="item.CoverImg" alt="" />
        <div class="center-item-font">
          {{ item.GoodsName }}
        </div>
        <div class="center-price">
          <div class="jifen">{{ item.DefaultPoints }}积分</div>
          <div>+</div>
          <div class="qian">
            <div class="center-price-money">￥</div>
            <div class="center-price-price">{{ item.DefaultPrice }}</div>
            <!-- <div class="center-price-people">{{item.BuyCount}}人付款</div> -->
          </div>
        </div>
        <!-- <div class="pre">
          <div class="pre-one">劵</div>
          <div class="pre-two">
            满{{ item.FullMoeny }}减{{ item.CouponMoeny }}
          </div>
        </div> -->
      </div>
      <el-pagination
        v-if="goodsList.length > 0"
        background
        :pager-count="7"
        :page-size="pageSize"
        :current-page.sync="pageIndex"
        layout="prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="total"
      >
      </el-pagination>
    </div>
    <div v-if="goodsList.length <= 0" style="margin-bottom: 50px">
      <nocontent name="暂无数据"></nocontent>
    </div>
    <Foter></Foter>
  </div>
</template>
<script>
import { qeryShopGoodsList, queryGoodsCategoryTree } from "@/api/shop";
import Topbar from '../../PCshop/components/topbar.vue'
import Foter from '../../PCshop/components/shopfooter.vue'
import SuS from '../../PCshop/components/suspen.vue' //悬浮
import TypeList from '../../PCshop/components/typelist.vue'
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    Topbar, Foter, SuS, TypeList, nocontent
  },
  data () {
    return {
      categoryList: [],
      isJf: false,
      value: "",
      goodsList: [],
      isLoad: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      categoryId: "",
      minPrice: "", //最低价
      maxPrice: "", //最高价
      minPoints: "",
      maxPoints: "",
      sortType: "asc",
      sortField: "Sort",
      total: 0, //总数
      //
      screenlist: [
        {
          name: '综合',
          ischeck: false
        },
        {
          name: '积分排序',
          ischeck: false
        },
      ],
      isfankui: '',
      type: [
        {
          name: '分类',
        },
        {
          name: '品牌',
        },
        {
          name: '艺术风格',
        },
        {
          name: '试用场景',
        },
        {
          name: '艺术种类'
        }
      ],
      typename: [
        {

        }
      ],
      twoarr: []
    }
  },
  created () {
    this.sus();
    this.postQeryShopGoodsList();
    this.postQeryShopGoodsCategoryList()
  },
  methods: {
    change (idx, arr) {
      arr.forEach((item, index) => {
        if (idx == index) {
          item.ischeck = item.ischeck == true ? false : true
        } else {
          item.ischeck = false
        }
      })
      if (idx == 0) {
        this.sortClick('Sort')
      } else if (idx == 1) {
        this.sortClick('DefaultPrice')
      }
    },
    // change2(item){
    //    console.log(item)
    //    this.$delete(item, "ischeck", "true");
    // },
    change3 (idx, arr) {
      console.log(arr[idx], 55)
      arr = arr.map(item => {
        this.$delete(item, "ischeck", "true");
        return item
      })
      this.$set(arr[idx], 'ischeck', 'true')
    },
    sus (payload) {
      this.isfankui = payload;
      console.log(this.isfankui)
    },
    //分类列表数据
    async postQeryShopGoodsCategoryList () {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response.slice(0, 6);
      console.log(this.categoryList, 87879879)
    },
    //   跳转到商品详情页
    togoodsdetail (id) {
      console.log(id)
      this.$router.push({ path: '/PCshop/goods/goodsdetail', query: { id: id } });
    },
    isjf () {
      this.isJf = true
    },
    shaixianmoney () {
      this.isJf = false
      this.postQeryShopGoodsList()
    },
    yichu () {
      this.isJf = false
    },
    //   筛选
    sortClick (sortField) {
      if (this.sortField != sortField) {
        this.sortType = "asc";
      } else {
        if (this.sortType == "asc") {
          this.sortType = "desc";
        } else if (this.sortType == "desc") {
          this.sortType = "asc";
        }
      }
      this.sortField = sortField;
      if (this.sortField == "Sort") {
        this.minPoints = '';
        this.maxPoints = '';
        this.sortType = "asc";
      }

      this.pageIndex = 1;
      this.goodsList = [];
      this.isEnd = false;
      this.postQeryShopGoodsList();
    },
    async postQeryShopGoodsList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.CategoryId = this.categoryId;
      parm.IsShelf = 1;
      parm.minPrice = this.minPrice;
      parm.maxPrice = this.maxPrice;
      parm.maxPoints = this.maxPoints;
      parm.minPoints = this.minPoints;
      parm.keyWords = this.value;
      parm.sortType = this.sortType;
      parm.sortField = this.sortField;
      parm.PayMehtod = 0;
      const res = await qeryShopGoodsList(parm);
      console.log(res, '积分详情');
      this.total = res.dataCount
      this.goodsList = res.data;
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postQeryShopGoodsList();
    },
  }
}
</script>
<style lang="scss" scoped>
.pc-title {
  width: 1100px;
  height: 55px;
  margin: 0 auto;
  line-height: 55px;
  color: #666666;
  font-size: 12px;
}
.bg {
  width: 1100px;
  height: 400px;
  margin: 0 auto;
  .img {
    width: 100%;
    height: 100%;
  }
}
.typebox {
  width: 1100px;
  margin: 0 auto;
}
.typebox-row {
  display: flex;
  .type-title {
    width: 110px;
    height: 36px;
    background: #efefef;
    font-size: 12px;
    color: #999999;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #e7e7e7;
  }
  .type-name {
    padding-left: 10px;
    flex: 1;
    background: #ffffff;
    height: 36px;
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    .type-name-font {
      position: relative;
      display: flex;
      margin-left: 30px;
      font-size: 12px;

      margin-top: 10px;
      .text2 {
        cursor: pointer;
        color: #333333;
      }
      .text3 {
        cursor: pointer;
        color: red;
      }
    }
  }
}
.screen {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  background: #ffffff;
  margin-top: 11px;
  border: 1px solid #eaeaea;

  .screen-left {
    width: 90px;
    height: 40px;
    border-right: 1px solid #eaeaea;
    text-align: center;
    line-height: 42px;
    font-size: 12px;
    color: #333333;
    cursor: pointer;
    &::after {
      content: " ";
      display: inline-block;
      width: 6px;
      height: 8px;
      background-image: url("../../../assets/images/typeicon.png");
      background-repeat: no-repeat;
      background-size: 100%;
      margin-left: 5px;
    }
  }
  .screen-left2 {
    width: 90px;
    height: 40px;
    border-right: 1px solid #eaeaea;
    text-align: center;
    line-height: 42px;
    font-size: 12px;
    color: #333333;
    cursor: pointer;
    &::after {
      content: " ";
      display: inline-block;
      width: 6px;
      height: 8px;
      background-image: url("../../../assets/images/typeicon2.png");
      background-repeat: no-repeat;
      background-size: 100%;
      margin-left: 5px;
    }
  }
  .mr {
    border-right: none;
    margin-right: -20px;
  }
  .screen-right {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: space-between;
    .price {
      .screen-shaixuan {
        display: flex;
        margin-top: 7px;
      }
      display: flex;
      .money {
        width: 80px;
        height: 29px;
        border: 1px solid #dddddd;
        font-size: 12px;
        color: #999999;
        padding-top: 4px;
        padding-left: 5px;
      }
      .line {
        width: 20px;
        height: 2px;
        margin: 14px 9px 0 9px;
        background: #999999;
      }
    }
    .nums {
      margin-left: auto;
      margin-top: 7px;
    }
    .jiantou {
      padding-right: 20px;
      img {
        margin-top: 4px;
        width: 23px;
        height: 28px;
      }
      display: flex;
    }
  }
}
.buts {
  width: 74px;
  height: 214px;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  position: fixed;
  top: 25.4%;
  left: 75%;
}
.center {
  width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  .center-item {
    margin-top: 20px;
    margin-right: 15px;
    width: 204px;
    background: #ffffff;
    padding: 28px 27px 20px 27px;
    box-sizing: border-box;
    img {
      width: 150px;
      height: 150px;
    }
    .center-item-font {
      margin-top: 30px;
      width: 168px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .center-price {
      display: flex;
      margin-top: 6px;
      // justify-content: space-between;
      .qian {
        display: flex;
      }
      .jifen {
        color: #f40707;
        font-size: 16px;
        font-weight: bold;
      }
      .center-price-money {
        color: #f40707;
        font-size: 12px;
        margin-top: 5px;
      }
      .center-price-price {
        color: #f40707;
        font-size: 16px;
        font-weight: bold;
      }
      .center-price-people {
        color: #999999;
        margin-top: 5px;
        margin-left: 8px;
      }
    }
    .pre {
      display: flex;
      .pre-one {
        width: 30px;
        height: 26px;
        line-height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
      }
      .pre-two {
        line-height: 26px;
        width: 78px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        margin-left: 10px;
      }
    }
  }
}
.banner {
  width: 1100px;
  margin: 20px auto 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.price2 {
  padding: 13px 10px;
  border: 1px solid #efefef;
  background: #ffffff;
  position: absolute;
  z-index: 999;
  top: -17px;
  left: -31px;
  display: flex;
  margin-top: 5px;
  margin-left: 20px;
  .queding {
    width: 70px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: rgb(242, 66, 52);
    color: #ffffff;
    margin-left: 20px;
  }
}
/deep/.el-pagination {
  width: 100%;
}
.therr-box {
  display: flex;
  display: none;
}
.therr-box2 {
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>